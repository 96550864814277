.user-card {
  * {
    box-sizing: border-box;
  }
  .flex {
    display: flex;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .fixed {
      position: fixed;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  
  .close-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
  }
  
  .m-scrollbar {
      overflow: hidden;
  
      &:hover {
          overflow: scroll;
      }
      /*** 全局滚动条样式 ***/
      &::-webkit-scrollbar { /* 缺省，必须 */
          position: absolute;
          right: 0;
          width: 6px;
          height: 6px;
      }
  
      &::-webkit-scrollbar-track { /* 滚动条的滑轨背景颜色 */
          border-radius: 3px;
          /*background-color: transparent;*/
          background-color: #F8F8F8;
      }
  
      &::-webkit-scrollbar-thumb { /* 滑块颜色 */
          border-radius: 3px;
          background-color: #ccc;
      }
  
      &::-webkit-scrollbar-button { /* 滑轨两头的监听按钮颜色 */
          height: 1px;
          background-color: transparent;
      }
  
      &::-webkit-scrollbar-corner { /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
          background-color: transparent;
      }
  }
  
  @for $i from 1 through 40 {
    .p-#{$i} {
      padding: #{$i}px;
    }
    .py-#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
    .px-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }
    .pl-#{$i} {
      padding-left: #{$i}px;
    }
    .pr-#{$i} {
      padding-right: #{$i}px;
    }
    .pb-#{$i} {
      padding-bottom: #{$i}px;
    }
    .pt-#{$i} {
      padding-top: #{$i}px;
    }

    .m-#{$i} {
      margin: #{$i}px;
    }
    .my-#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }
    .mx-#{$i} {
      margin-left: #{$i}px;
      margin-right: #{$i}px;
    }
    .ml-#{$i} {
      margin-left: #{$i}px;
    }
    .mr-#{$i} {
      margin-right: #{$i}px;
    }
    .mb-#{$i} {
      margin-bottom: #{$i}px;
    }
    .mt-#{$i} {
      margin-top: #{$i}px;
    }
  }
  .pointer {
      cursor: pointer;
  }
  
  .hover\:opacity-90:hover {
      opacity: 0.9;
  }
  .hover\:opacity-80:hover {
      opacity: 0.8;
  }
  .hover\:opacity-70:hover {
      opacity: 0.7;
  }
  .hover\:opacity-60:hover {
      opacity: 0.7;
  }
  
}