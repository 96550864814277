.pay-wrap {
    padding: 0 12px;
    h3 {
        font-size: 20px;
        text-align: center;
        line-height: 20px;
        height: 20px;
        margin: 25px 0;
    }

    .rights{
        height: 89px;
        background: #F5FBFA;
        border-radius: 16px;
        padding: 9px 18px 11px;
        box-sizing: border-box;
        .head{
            width: 152px;
            margin: 0 auto;
        }

        .content{
            display: flex;
            margin-top: 11px;

            .item-2 {
                margin-left: 71px;
            }

            .item{
                display: flex;
                column-gap: 10px;
                // flex: 1;
                align-items: center;
                .left{
                    width: 40px;
                    height: 40px;
                    background: #00BCA6;
                    border-radius: 50%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .right{
                    .title{
                        color: #00BCA6;
                        font-weight: 500;
                        font-size: 13px;
                    }
                    .time{
                        margin-top: 4px;
                        font-size: 11px;
                        color: #4F4F6B;
                    }
                }
            }

        }
    }

    .vip-list {
        display: flex;
        // grid-template-columns: 1fr 1fr 1fr;
        // grid-column-gap: 9px;
        margin-bottom: 12px;
        padding-top: 32px;

        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding-left: 2px;
        padding-bottom: 2px;
        padding-right: 2px;
        flex-wrap: nowrap;
        .vip-item {
            margin-right: 9px;
            position: relative;
            box-sizing: border-box;
            border-radius: 8px;
            outline: 1px solid #f2f2f2;
            // width: 103px;
            // flex-shrink: 0;
            // flex: 1;
            width: 33%;
            p {
                text-align: center;
            }
            .tag {
                position: absolute;
                top: 0;
                left: -5px;
                // width: 50%;
                transform: translateY(-50%);
                display: inline-block;
                color: #fff;
                border-radius: 10px 0px 10px 0px;
                background-color: #ff4e4f;
                padding: 5px 8px;
                font-size: 9px;
            }
            .title {
                font-size: 16px;
                padding-top: 12px;
                margin-bottom: 6px;
            }
            .price {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 20px;
                span {
                    font-size: 28px;
                }
            }
            .divide {
                margin: 0 12px;
                height: 1px;
                background-color: #f2f3f4;
                margin-bottom: 15px;
            }
            .m-price {
                font-size: 12px;
                color: #ff4e4f;
                margin-bottom: 8px;
            }
            .o-price {
                margin: 25px 0;
                font-size: 12px;
                color: #a4a7af;
                text-decoration: line-through;
            }
        }
        .vip-item.active {
            background-color: rgba(1, 152, 133, 0.04);
        }
        .active {
            outline: 2px solid #00bca6;
            .divide {
                background-color: #d8f3ef;
            }
        }
        
    }
    .vip-list::-webkit-scrollbar {
        display: none;
    }

    .jz-resume-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 8px;
        height: 40px;
        border-radius: 8px;
        text-align: center;
        font-size: 13px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }
    .wx-btn {
        background-color: #04be02;
        margin-bottom: 14px;
    }
    .ali-btn {
        background: #1776fe;
    }
    .tip {
        margin-top: 16px;
        margin-bottom: 0;
        text-align: center;
        font-size: 12px;
        color: #A6A6A6;
        span {
            color: #04BE02;
        }
    }
    .agreement {
        margin-top: 10px;
        margin-bottom: 0;
        text-align: center;
        font-size: 12px;
        color: #A6A6A6;
        span {
            color: rgba(23, 118, 254, 1);
        }
    }
    .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin: 25px 0;
        .tab-item {
            color: #979797;
            position: relative;
        }
        .active {
            font-weight: bold;
            color: #2C2C2C;
        }
        .active::after {
            // content: "";
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            height: 4px;
            background: #00BCA6;
            border-radius: 2px;
        }
        .tab-item + .tab-item {
            margin-left: 48px;
        }
    }
    .vip-info {
        background: #F5FBFA;
        padding: 10px 18px;
        margin-bottom: 25px;
        border-radius: 8px;
        .vip-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 11px;
            img {
                width: 168px;
                height: 18px;
            }
        }
        .info {
            display: flex;
            &-item {
                flex: 1;
                display: flex;
                align-items: center;
                // display: inline-block;
                font-size: 10px;
                color: #4C535C;
                .img {
                    width: 40px;
                    height: 40px;
                    background: #00BCA6;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    img {
                        width: 24px;
                        height: 24px;
                    }
                }

                .content {
                    flex: 1;
                    color: #00BCA6;
                    font-size: 13px;
                    font-weight: bold;
                    white-space: nowrap;
                    span {
                        margin-top: 4px;
                        font-weight: normal;
                        font-size: 11px;
                        display: block;
                        color: #4F4F6B;
                    }
                }
                margin-right: 24px;
            }
        }
    }
    .footer {
        padding-top: 13px;
        padding-bottom: 24px;
    }
    .ios-miniprogram-btn {
        height: 49px;
        line-height: 49px;
        border-radius: 25px;
        text-align: center;
        width: 100%;
        font-size: 15px;
        color: #fff;
        background: #11BBA6;
    }
    .skeleton {
        margin-bottom: 76px;
    }
    .qr-code-pay {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        padding-bottom: 15px;
        padding-top: 12px;
        .qr-code {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > img {
                width: 90px;
                margin-top: 12px;
            }
        }
    }
 
}

.svip {
    .ios-miniprogram-btn {
        color: #6F4B23;
        background: linear-gradient(90deg, #F7DFA1 0%, #F8D183 100%);
    }
    .vip-info {
        background: #FCF9EF;
        .info-item {
            .img {
                background: linear-gradient(90deg, #F7DFA1 0%, #F8D183 100%);
            }
            .content {
                color: #EDB769;
            }
        }
    }
    .vip-list {
        .vip-item.active {
            background: #FFFBF4;
            outline: 2px solid #EDB769;
            .divide {
                background-color: #FFEED6;
            }
        }
    }
    .tab {
        color: #2C2C2C;
        .active::after {
            background: #EDB769;
        }
    }
}

.ios-wxapp-modal {
    background: linear-gradient(180deg, #E5F8F5 0%, #FFFFFF 100%);
    .adm-modal-title {
        margin-bottom: 36px;
        font-size: 16px;
    }
    .adm-auto-center-content {
        font-size: 15px;
        padding-bottom: 21px;
    }
    .adm-modal-footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 13px;
        .adm-space-item {
            flex: 1;
            margin-bottom: 0;

            font-size: 15px;
            button {
                // height: 88px;
                // line-height: 88px;
                border-radius: 8px;
                padding: 9px 38px;

                span {
                    font-size: 15px;
                }
            }
        }
        .adm-space-item:first-child {
            button {
                background: #E3F7F4;
                border: none;
                color: #11BBA6;
                // height: 88px;
                // line-height: 88px;
                span {
                    font-size: 15px;
                }
            }
        }
    }
}

.adm-center-popup-wrap {
    width: 85vw;
    max-width: 85vw;
}

.pay-wrap-modal .bottom-modal-content {
    top: auto;
    // height: 480px;
    overflow: hidden;
}

.pay-wrap-modal.min-size-client .bottom-modal-content{
    top: auto;
    // height: 480px;
}

.ios-miniprogram-wrap .bottom-modal-content{
    top: 50%;
}

.ppt-plugin-wrap .bottom-modal-content{
    top: 5%;
}
.pay-modal-flex-line{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.pay-modal-origin-price{
    transform: translate(0px, -17px);
    display: flex;
    width: 100%;
    .word{
        padding-left: 12px;
        color: #1D2129;
        width:183px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        &>div:nth-child(1){
            padding-right: 10px;
        }
    }
    .red-word{
        padding-left:5px ;
        color: #EF333F;
        font-size: 14px;
    }
    .view-word{
        align-items: center;
        // padding-left: 333px;
        position: absolute;
        right: 11px;
        &>div:nth-child(1){
            padding-right: 2px;
        }
        position: absolute;
        display: flex;
        color: #BF9790;
        cursor: pointer;
        &:hover{
            color: #987873;
        }
    }
}
.pay-modal-origin-price-modal{
    z-index: 100000;
    .adm-center-popup-wrap .adm-modal-body .adm-modal-content{
        padding:  0px !important ;
    }
}